import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { UseForm } from "../../hooks";
import { UsePostDataForm, UsePutDataForm } from "../../hooks/api";
import { useState } from "react";

const UseFormCategories = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isReady, setReady] = useState(false);

  const { isLoading: isLoadingPostDataForm, handlePostDataForm } =
    UsePostDataForm();
  const { isLoading: isLoadingPutDataForm, handlePutDataForm } =
    UsePutDataForm();

  const { form, setForm, setContent, handleChange, content, handleChangeRich } =
    UseForm();

  useEffect(() => {
    setForm({
      ...form,
      status: "draft",
      is_trending: false,
    });
    if (props.type === "put") {
      if (location.state) {
        setForm(location.state);
        setContent(location.state.desc);
      } else {
        navigate(props.redirect);
      }
      setReady(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    if (props.type === "post") {
      if (form.image.type === "image/jpeg" || form.image.type === "image/png") {
        let params = {
          url: "/v1/kategori",
          form: {
            ...form,
            category_type: props.categoryType,
            desc: content,
          },
          navigation: props.redirect,
        };
        console.log(form);
        // if (form.type_of_plant.length < 1) {
        //  swal("Gagal", "Jenis Tanaman harus diisi", "error");
        // } else {
          handlePostDataForm(params);
        // }
      } else {
        swal("Gagal", "File Harus Gambar", "error");
      }
    } else {

      if (form.image && typeof form.image !== "string") {
        if (
          form.image.type === "image/jpeg" ||
          form.image.type === "image/png"
        ) {
          let params = {
            url: "/v1/kategori?_id=" + form._id,
            form: {
              ...form,
              category_type: props.categoryType,
              desc: content,
            },
            navigation: props.redirect,
          };
          // if (form.type_of_plant.length < 1) {
          //   swal("Gagal", "Jenis Tanaman harus diisi", "error");
          // } else {
            handlePutDataForm(params);
          // }
        } else {
          swal("Gagal", "File Harus Gambar", "error");
        }
      } else {
        console.log(form);
        let params = {
          url: "/v1/kategori?_id=" + form._id,
          form: {
            ...form,
            category_type: props.categoryType,
            desc: content,
          },
          navigation: props.redirect,
        };
        delete params.form.image;
        // if (form.type_of_plant.length < 1) {
        //   swal("Gagal", "Jenis Tanaman harus diisi", "error");
        // } else {
          handlePutDataForm(params);
        // }
      }
    }
  };

  const isEnable = content;

  return {
    isReady,
    isLoadingPostDataForm,
    isLoadingPutDataForm,
    form,
    navigate,
    handleChange,
    handleChangeRich,
    content,
    handleSubmit,
    isEnable,
  };
};

export default UseFormCategories;
